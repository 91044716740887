@font-face {
    font-family: screaming-neon;
    src: local('screaming-neon'), url(./fonts/screaming-neon.ttf) format('truetype');
}
@font-face {
    font-family: manilla-20xx;
    src: local('manilla-20xx'), url(./fonts/manilla-20xx.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
